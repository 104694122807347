import ProductOptionsActionTypes from '../actionTypes/ProductOptionsActionTypes';

const ProductOptionsForCongruentPPReducer = function ProductOptionsForCongruentPPReducer(
    state = {},
    action
) {
    switch (action.type) {
        case ProductOptionsActionTypes.PRODUCTOPTIONS_ON_CLICK:
            return action.payload;
        default:
            return state;
    }
};

// ProductOptionsForCongruentPPReducer.reducerName = 'productOptions';

export default ProductOptionsForCongruentPPReducer;

import { takeLatest, put, call, race, select, delay } from 'redux-saga/effects';
import { getCriteoApi } from 'yoda-interfaces/lib/Criteo/CriteoApi';

import { deviceMapping, pageTypeMapping } from 'yoda-interfaces/lib/Criteo/CriteoApi.config';

import { getSponsoredApi } from 'yoda-interfaces/lib/SponsoredProduct/SponsoredProductApi';

import isEmpty from 'lodash/isEmpty';
import { selectFeatureFlags } from '../selectors/ContextSelector';

import {
    getCriteoPageEnv,
    noLogCheck,
    customerIdCheck,
    getRetailVisitorId,
} from '../helpers/Criteo/Criteo';

import {
    CRITEO_GET_REQUEST,
    CRITEO_GET_SUCCESS,
    CRITEO_GET_ERROR,
    SPONSORED_GET_RESULTS,
    CRITEO_WAIT_FOR_DATA,
    CRITEO_GET_GROUP_BEACONS,
} from '../actionTypes/CriteoActionTypes';

import { triggerFormError } from '../actions/AnalyticsAction';

/* istanbul ignore next */
const getStoreContext = (state) => state.context;

const getFeatureFlags = (state) => selectFeatureFlags(state);

function* criteoProductsSaga(params) {
    const sagaStartTime = Date.now();
    const {
        enableSponsoredProduct = false,
        enableCriteoTestCampaign = true,
        enableIOverrideCriteoTestCampaing = false,
    } = yield select(getFeatureFlags);
    try {
        const storeContext = yield select(getStoreContext);
        // need to configure in preferences set to 120-150ms after pre-launch
        const pageTimeout =
            storeContext?.preferences?.criteoPageTimeout?.[params?.payload?.pageType];
        let timeout = pageTimeout || storeContext?.preferences?.criteoTimeout || 1000;
        if (!params?.payload?.waitForCriteo) timeout = 20000;
        const deviceType = storeContext?.deviceType || {};
        const isNative = storeContext?.isNative || false;
        const hostname = storeContext?.hostname ?? '';
        const env = storeContext?.env ?? '';
        const sponsoredProductsExcludeFilterParams =
            storeContext?.preferences?.sponsoredProductsExcludeFilterParams || [];
        const sponsoredProductsEnableFilters =
            storeContext?.preferences?.sponsoredProductsEnableFilters || [];
        const criteoUrl = storeContext?.preferences?.criteoUrl || '';
        const latencyStatusCodeCriteo = storeContext?.preferences?.latencyStatusCodeCriteo || 2;
        const latencyStatusCodeSP = storeContext?.preferences?.latencyStatusCodeSP || 2;

        const updatedParams = {
            ...params.payload,
            env: getCriteoPageEnv(deviceType, isNative),
            nolog: noLogCheck(hostname, env),
            customerId: customerIdCheck(),
            retailVisitorParam: getRetailVisitorId(),
            sponsoredProductsExcludeFilterParams,
            sponsoredProductsEnableFilters,
            ...(enableCriteoTestCampaign && {
                lowerEnvQueryConfig: {
                    enableCriteoTestCampaign,
                    enableIOverrideCriteoTestCampaing,
                    isProduction:
                        hostname === 'www.jcpenney.com' || hostname === 'preview.jcpenney.com',
                },
            }), // Add this parameter
        };

        yield put({
            type: CRITEO_WAIT_FOR_DATA,
            waitForCriteo: params?.payload?.waitForCriteo ?? false,
        });
        const criteoApiCall = getCriteoApi(updatedParams, criteoUrl);
        const criteoStartTime = Date.now();
        const criteoCallData = yield race({
            res: criteoApiCall || {},
            timeout: delay(timeout),
        });
        const criteoEndTime = Date.now();
        const criteoResponseTime = criteoEndTime - criteoStartTime;
        const clickStreamUrl = 'https://api.jcpclick.com/p/collector';
        setTimeout(() => {
            const beaconPayload = {
                eventName: 'criteo performance metrics',
                criteoApiData: {
                    ResponseTime: criteoResponseTime,
                    CountOfCriteoCalls: 1,
                    NumberOfRetries: 0, // Assuming no retries in this context
                    NumberOfTimeouts: criteoCallData?.timeout ? 1 : 0,
                    StatusCodes: criteoCallData?.res ? criteoCallData?.res?.status : 'timeout',
                    UserID: window?.digitalData?.user?.profile?.profileInfo?.hitID,
                    criteoServerResponseTime: criteoCallData?.res?.headers?.get('server-timing'),
                },
            };

            if (criteoCallData?.timeout || criteoCallData?.res?.status !== 200) {
                const errorCode = criteoCallData?.timeout ? 'timeout' : criteoCallData?.res?.status;
                beaconPayload.StatusCodes = errorCode;
                navigator.sendBeacon(clickStreamUrl, JSON.stringify(beaconPayload));
            } else {
                navigator.sendBeacon(clickStreamUrl, JSON.stringify(beaconPayload));
            }
        }, 2000);

        if (!criteoCallData?.timeout) {
            if (criteoCallData?.res?.data?.status === 'OK') {
                if (
                    enableSponsoredProduct &&
                    criteoCallData?.res?.data?.placements?.length > 0 &&
                    params?.payload?.waitForCriteo
                ) {
                    let sponsoredData = [];
                    let sponsoredProduct;
                    const pageMapping = pageTypeMapping(params?.payload);
                    if (criteoCallData?.res?.data?.placements?.length > 0) {
                        const placement = criteoCallData?.res?.data?.placements?.[0];
                        const pageMappingModule = `${pageMapping?.eventType}_API_${
                            deviceMapping[updatedParams?.env]
                        }-InGrid`;

                        const productData = placement[pageMappingModule][0]?.products;
                        if (productData?.length > 0) {
                            const productDetails = productData.map((item) => {
                                const productParams = { ppId: item.ParentSKU };
                                if (!isEmpty(item?.ProductId)) {
                                    productParams.skuId = item.ProductId;
                                }
                                return productParams;
                            });
                            const sponsoredProductTimeout =
                                storeContext?.preferences?.sponsoredProductTimeout?.[
                                    params?.payload?.pageType
                                ] || 15000;
                            const browseStartTime = Date.now();

                            sponsoredProduct = yield race({
                                res: call(getSponsoredApi, productDetails) || {},
                                timeout: delay(sponsoredProductTimeout),
                            });
                            const browseEndTime = Date.now();
                            if (
                                sponsoredProduct?.res?.status === 200 ||
                                sponsoredProduct?.res?.status === 207 ||
                                sponsoredProduct?.res?.status === 216
                            ) {
                                if (
                                    sponsoredProduct?.data?.response &&
                                    sponsoredProduct.data.response?.length === 0
                                ) {
                                    yield put(
                                        triggerFormError([
                                            {
                                                errorDescription: `criteo ad - browse api with empty result`,
                                            },
                                        ])
                                    );
                                } else {
                                    const mergedData = productData.map((value) => {
                                        const mergedCriteoBrowse = sponsoredProduct?.res?.data?.response.find(
                                            (data) => data.ppId === value.ParentSKU
                                        );
                                        return mergedCriteoBrowse
                                            ? { ...value, ...mergedCriteoBrowse }
                                            : { ...value };
                                    });

                                    sponsoredData = mergedData;
                                }
                            } else {
                                const errorDescription = sponsoredProduct?.timeout
                                    ? `criteo ad - browse api read timeout | ${
                                          browseEndTime - browseStartTime
                                      } ms`
                                    : `criteo ad - browse api error: ${sponsoredProduct?.res?.status}`;

                                yield put(triggerFormError([{ errorDescription }]));

                                if (sponsoredProduct?.timeout) {
                                    if (productDetails?.length > 0) {
                                        const onLoadBeaconCall =
                                            placement?.[pageMappingModule]?.[0]?.OnLoadBeacon;
                                        let products = '';
                                        let status = '';

                                        productDetails?.forEach((product) => {
                                            if (
                                                product?.ppId !== undefined &&
                                                product?.skuId !== undefined
                                            ) {
                                                products += `${product?.skuId}|`;
                                                status += `${latencyStatusCodeSP}|`;
                                            }
                                        });

                                        products = products?.slice(0, -1)?.replace(/"/g, ''); // Remove the last pipe
                                        status = status?.slice(0, -1); // Remove the last pipe
                                        navigator.sendBeacon(
                                            `https:${onLoadBeaconCall}&ex=${products}&st=${status}`
                                        );
                                    }
                                }
                            }
                        }
                        yield put({
                            type: CRITEO_GET_SUCCESS,
                            criteoCallData: criteoCallData?.res?.data,
                        });
                        yield put({
                            type: CRITEO_GET_GROUP_BEACONS,
                            criteoGroupBeacons: placement[pageMappingModule][0],
                        });
                    }
                    yield put({
                        type: SPONSORED_GET_RESULTS,
                        sponsoredProduct: {
                            status: sponsoredProduct?.res?.status || '',
                            data: {
                                allProducts: sponsoredData,
                                qualifiedProducts: sponsoredData?.filter(
                                    (item) => item?.ppActive === true && item?.inStock === true
                                ),
                                OOSProducts: sponsoredData?.filter(
                                    (item) => item?.ppActive === true && item?.inStock === false
                                ),
                                ProductsNotFound: sponsoredData?.filter(
                                    (item) => item?.ppActive === false
                                ),
                            },
                        },
                    });
                } else {
                    yield put({
                        type: SPONSORED_GET_RESULTS,
                        sponsoredProduct: { status: '', data: {} },
                    });
                }
            } else {
                /* logic to log errors we get back from criteo. we will get back an error attribute with an array of errors along with status "error"
                ex: 
                status: "error"
                errors: ["Invalid Required Input : page-id is not a valid input. Review passed in value"]
                */
                const status = criteoCallData?.res?.status;
                const errorDescription =
                    status >= 400 && status < 600
                        ? `criteo ad - criteo error ${status}`
                        : criteoCallData?.res?.data?.status === 'Error'
                        ? `criteo ad - criteo data error ${criteoCallData?.res?.data?.errors[0]}`
                        : null;

                if (errorDescription) {
                    yield put(triggerFormError([{ errorDescription }]));
                }
                yield put({
                    type: SPONSORED_GET_RESULTS,
                    sponsoredProduct: { status: '', data: {} },
                });
            }
        } else {
            // timeout logging error here
            yield put({ type: CRITEO_GET_ERROR, error: 'timeout' });
            if (enableSponsoredProduct) {
                yield put({
                    type: SPONSORED_GET_RESULTS,
                    sponsoredProduct: { status: '', data: {} },
                });
            }
            if (params?.payload?.waitForCriteo) {
                yield put(
                    triggerFormError([
                        {
                            errorDescription: `criteo ad - criteo read timeout | ${criteoResponseTime} ms`,
                        },
                    ])
                );
            }

            // Latency OnLoadBeacon call logic -criteo
            if (params?.payload?.waitForCriteo || criteoCallData?.timeout) {
                // Using yield call to maintain saga pattern
                const criteoRes = yield call(() => criteoApiCall);
                const pageMapping = pageTypeMapping(params?.payload);
                if (criteoRes?.data?.placements?.length > 0) {
                    const placement = criteoRes?.data?.placements?.[0];
                    const pageMappingModule = `${pageMapping?.eventType}_API_${
                        deviceMapping[updatedParams?.env]
                    }-InGrid`;

                    const productData = placement?.[pageMappingModule][0]?.products;
                    const onLoadBeaconCall = placement?.[pageMappingModule]?.[0]?.OnLoadBeacon;
                    if (productData?.length > 0) {
                        const productDetails = productData.map((item) => {
                            const productParams = { ppId: item.ParentSKU };
                            if (!isEmpty(item?.ProductId)) {
                                productParams.skuId = item.ProductId;
                            }
                            return productParams;
                        });

                        if (productDetails?.length > 0) {
                            let products = '';
                            let status = '';

                            productDetails?.forEach((product) => {
                                if (product?.ppId !== undefined && product?.skuId !== undefined) {
                                    products += `${product?.skuId}|`;
                                    status += `${latencyStatusCodeCriteo}|`;
                                }
                            });

                            products = products?.slice(0, -1)?.replace(/"/g, ''); // Remove the last pipe
                            status = status?.slice(0, -1); // Remove the last pipe
                            navigator.sendBeacon(
                                `https:${onLoadBeaconCall}&ex=${products}&st=${status}`
                            );
                        }
                    }
                }
            }
        }
    } catch (error) {
        console.log(`Criteo Saga error : ${error}`);
        yield put({ type: CRITEO_GET_ERROR, error });
        yield put(
            triggerFormError([
                {
                    errorDescription: `criteo ad - criteo saga error : ${error}`,
                },
            ])
        );
        if (enableSponsoredProduct) {
            yield put({
                type: SPONSORED_GET_RESULTS,
                sponsoredProduct: { status: '', data: {} },
            });
        }
    }
    const sagaEndTime = Date.now();
    console.log(`criteo saga total time | ${sagaEndTime - sagaStartTime} ms`);
}

export const watchCriteoProductsRequest = function* watchCriteoProductsRequest() {
    yield takeLatest(CRITEO_GET_REQUEST, criteoProductsSaga);
};

watchCriteoProductsRequest.sagaName = 'watchCriteoProductsRequest';

export default watchCriteoProductsRequest;

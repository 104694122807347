const PRODUCTOPTIONS_ON_CHANGE = 'PRODUCTOPTIONS_ON_CHANGE';
const PRODUCTOPTIONS_ON_RESET = 'PRODUCTOPTIONS_ON_RESET';
const PRODUCTOPTIONSBUNDLE_ON_CHANGE = 'PRODUCTOPTIONSBUNDLE_ON_CHANGE';
const PRODUCTOPTIONS_ON_CLICK = 'PRODUCTOPTIONS_ON_CLICK';
const RESET_ATC_BTN_CLICK = 'RESET_ATC_BTN_CLICK';

export default {
    PRODUCTOPTIONS_ON_CHANGE,
    PRODUCTOPTIONS_ON_RESET,
    PRODUCTOPTIONSBUNDLE_ON_CHANGE,
    PRODUCTOPTIONS_ON_CLICK,
    RESET_ATC_BTN_CLICK,
};
